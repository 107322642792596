
import Vue, { PropType } from 'vue';
import {
  ChartConfiguration, ChartDataset, Chart, registerables, LegendItem, CategoryScale,
} from 'chart.js';
import ZoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import XAxisRedrawPlugin from '@/utils/charts/plugins/XAxisRedrawPlugin';
import LineChartCustomTooltipPlugin from '@/utils/charts/plugins/LineChartCustomTooltipPlugin';
import LineChartCustomTooltip from '@/components/analytics/charts/LineChartCustomTooltip.vue';
import type { CustomTooltipData } from '@/utils/charts/plugins/LineChartCustomTooltipPlugin';
import CustomChartLegendPlugin from '@/utils/charts/plugins/CustomChartLegendPlugin';
import labelToArray from '@/utils/charts/labelToArray';
import ChartLegend from './ChartLegend.vue';
import ChartScrollButtons from './ChartScrollButtons.vue';
import { BarChartDataSet, BarChartDataSetData } from './types';

Chart.register(...registerables);
Chart.register(ZoomPlugin);

const MIN_X_STEP_SIZE = 165;
const TICK_LENGTH = 32;
const TICK_FONT_SIZE = 10;
const TICK_FONT_SIZE_SMALL = 8;

export default Vue.extend({
  name: 'BarChart',

  components: {
    ChartLegend,
    ChartScrollButtons,
    LineChartCustomTooltip,
  },

  props: {
    xLabels: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    dataSets: {
      type: Array as PropType<BarChartDataSet[]>,
      default: () => [],
    },
    minY: {
      type: Number,
      default: 0,
    },
    maxY: {
      type: Number,
      default: 100,
    },
    maxBarThickness: {
      type: Number,
      default: 26,
    },
    yStepSize: {
      type: Number,
      default: 1,
    },
    hideYScaleLabels: {
      type: Boolean,
      default: false,
    },
    hideLegend: {
      type: Boolean,
      default: false,
    },
    yScaleLabelsFormatter: {
      type: Function,
      default: (val: any) => val,
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    chartScroll: {
      type: Boolean,
      default: true,
    },
    smallFont: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: null as Chart<'bar' | 'line', BarChartDataSetData[]> | null,
      xScaleWidth: 0,
      leftValueIndex: 0,
      legendItems: [] as LegendItem[],
      tooltipData: { title: '', items: [] } as CustomTooltipData,
    };
  },

  computed: {
    minTickWidth(): number {
      return (this.chartScroll ? MIN_X_STEP_SIZE : 1) * this.dataSets.length;
    },
    tickFontSize(): number {
      return this.smallFont ? TICK_FONT_SIZE_SMALL : TICK_FONT_SIZE;
    },
    maxVisibleTicksCount(): number {
      return Math.floor(this.xScaleWidth / this.minTickWidth);
    },
    tickWidth(): number {
      return this.xScaleWidth / this.rightValueIndex;
    },
    rightValueIndex(): number {
      if (this.chartLabels.length <= this.maxVisibleTicksCount) {
        return this.chartLabels.length;
      }

      return this.leftValueIndex + this.maxVisibleTicksCount + 1;
    },
    chartLabels(): Array<string | number> {
      return [...Array(this.xLabels.length).keys()];
    },
    preparedDataSets(): ChartDataset<'bar' | 'line', BarChartDataSetData[]>[] {
      return this.dataSets.map((dataSet) => ({
        ...dataSet,
        parsing: {
          yAxisKey: 'y',
          xAxisKey: 'x',
        },
        maxBarThickness: this.maxBarThickness,
        minBarLength: 2,
      }));
    },
    chartSettings(): ChartConfiguration<'bar' | 'line', BarChartDataSetData[]> {
      const self: Vue = this;

      return {
        type: 'bar',
        data: {
          labels: this.chartLabels,
          datasets: this.preparedDataSets,
        },
        options: {
          devicePixelRatio: 4,
          responsive: true,
          onResize: this.updateVisibleArea,
          maintainAspectRatio: false,
          scales: {
            y: {
              position: 'right',
              suggestedMin: this.minY,
              suggestedMax: this.maxY,
              ticks: {
                labelOffset: this.tickFontSize / 2,
                stepSize: this.yStepSize,
                color: '#8F9295',
                align: 'end',
                padding: this.tickFontSize,
                display: !this.hideYScaleLabels,
                callback: (val) => this.yScaleLabelsFormatter(val),
                font: {
                  size: this.tickFontSize,
                },
              },
              grid: {
                borderDash: [2, 2],
                tickBorderDash: [2, 2],
                tickLength: this.hideYScaleLabels ? 0 : TICK_LENGTH,
                borderWidth: 1,
                borderColor: 'D8D9DA',
                color: 'D8D9DA',
              },
            },
            x: {
              grid: {
                borderColor: '#EBECED',
                color: '#EBECED',
                borderWidth: 1,
                tickLength: 2,
              },
              ticks: {
                callback(val) {
                  // @ts-ignore
                  const label = self.xLabels[val as number] || '';
                  // @ts-ignore
                  return labelToArray(
                    label,
                    Math.floor(self.tickWidth / (self.tickFontSize * 0.70)), 3 + Number(self.smallFont),
                  );
                },
                font: {
                  size: this.tickFontSize,
                  family: 'Roboto Mono',
                },
                padding: this.tickFontSize,
              },
            },
          },
          elements: {
            point: {
              radius: 0,
            },
            line: {
              borderWidth: 3,
            },
          },
          plugins: {
            datalabels: {
              align: 'top',
              anchor: 'end',
              offset: 4,
              font: {
                size: this.tickFontSize,
                family: 'Roboto',
              },
              color: '#63676B',
              formatter: (value) => value.label,
              // Скрываем выходящие за рамки лэйблы
              display: (context) => context.dataIndex >= this.leftValueIndex
                && context.dataIndex < this.rightValueIndex,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            lineChartCustomTooltip: {
              tooltip: {
                tooltipEl: () => (this.hideTooltip ? null : self.$refs.chartTooltip as HTMLDivElement),
              },
              callbacks: {
                onTooltipDataUpdate: (e) => {
                  // @ts-ignore
                  self.tooltipData = e;
                },
              },
            },
          },
          layout: {
            padding: {
              top: 24,
            },
          },
        },
        plugins: [
          ChartDataLabels,
          XAxisRedrawPlugin(TICK_LENGTH),
          CustomChartLegendPlugin(this, 'legendItems'),
          LineChartCustomTooltipPlugin,
        ],
      };
    },
  },

  watch: {
    chartSettings: {
      handler(val) {
        if (!this.chart) {
          return;
        }

        const { data, options } = val;
        const { datasets, labels } = data;
        this.chart.data.datasets = datasets;
        this.chart.data.labels = labels;
        this.chart.options = options;

        this.chart.update();
        this.updateVisibleArea();
      },
    },
    leftValueIndex() {
      this.updateVisibleArea();
    },
  },

  mounted() {
    this.chart = new Chart(this.$refs.canvasEl as HTMLCanvasElement, this.chartSettings);
    this.chart.update();
    this.updateVisibleArea();
  },

  beforeDestroy() {
    this.chart?.destroy();
  },

  methods: {
    updateVisibleArea() {
      if (!this.chart) {
        return;
      }

      // @ts-ignore
      const xScale: CategoryScale = this.chart.boxes.find((item) => item.axis && item.axis === 'x');
      this.xScaleWidth = xScale?.maxWidth;

      this.chart!.zoomScale('x', {
        min: this.leftValueIndex,
        max: Math.min(this.leftValueIndex + this.maxVisibleTicksCount, this.chartLabels.length),
      }, 'normal');
    },
    moveLeft() {
      this.leftValueIndex -= 1;
    },
    moveRight() {
      this.leftValueIndex += 1;
    },
  },
});
